"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var trunk = function () {
    var items = document.querySelectorAll('.trunk-slide');
    var content = document.querySelector('.trunk-content');
    var toggleBtn = document.querySelector('#trunkNav');
    var toggleFiltrtBtn = document.querySelector('#trunkFilter');
    var toggleCatalogBtn = document.querySelector('#trunkCatalog');
    var triggers = ['trunkNav', 'trunkFilter', 'trunkCatalog'];
    var open = function (selector) {
        var nav = document.querySelector("." + selector);
        nav.classList.add('open');
        nav.classList.remove('close');
        content.classList.add('open');
        content.classList.remove('close');
        var trigger = document.querySelector("#" + selector);
        trigger.classList.add('active');
    };
    var close = function () {
        items.forEach(function (item) {
            item.classList.add('close');
            item.classList.remove('open');
        });
        toggleBtn.classList.remove('active');
        toggleFiltrtBtn.classList.remove('active');
        toggleCatalogBtn.classList.remove('active');
    };
    triggers.forEach(function (triggerSelector) {
        var trigger = document.querySelector("#" + triggerSelector);
        if (!trigger)
            return false;
        trigger.addEventListener('click', function (event) {
            event.stopPropagation();
            event.preventDefault();
            console.log(event.target);
            if (content.classList.contains('open')) {
                close();
                return true;
            }
            if (event.target.nodeName === 'I') {
                open(event.target.parentElement.id);
            }
            else if (event.target.nodeName === 'SPAN') {
                open(event.target.parentElement.id);
            }
            else {
                open(event.target.id);
            }
        });
    });
    var contentClose = function () {
        if (content.classList.contains('open')) {
            close();
        }
    };
    content && content.addEventListener('click', contentClose);
};
exports.default = trunk;
