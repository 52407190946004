"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require("./");
var submiter_ts_1 = require("../submiter.ts");
var FormValidate = /** @class */ (function () {
    // antiSpamCounterInput:HTMLInputElement;
    // isRobotInput:HTMLInputElement;
    function FormValidate(props) {
        var _this = this;
        this.checkInputs = function (inputs) {
            inputs.forEach(function (input) {
                var wrapper = input.parentNode;
                var errorTextBox = wrapper.querySelector(_this.inputErrorTextSelector);
                _1.inputValidate(input, wrapper, errorTextBox);
            });
        };
        this.checkInput = function (event) {
            if (!event.target.classList.contains('required'))
                return true;
            var wrapper = event.target.parentNode;
            var errorTextBox = wrapper.querySelector(_this.inputErrorTextSelector);
            _1.inputValidate(event.target, wrapper, errorTextBox);
            _this.updateFormStatus();
        };
        this.updateFormStatus = function () {
            if (_this.form.querySelector('.error') || _this.form.dataset.isrobot === 'true') {
                _this.state.formStatus = 'invalid';
                _this.formErrorText && _this.formErrorText.classList.remove('hide');
                // if(this.form.dataset.isrobot === 'true'){
                //     this.botErrorText && this.botErrorText.classList.remove('hide')
                // }
            }
            else {
                _this.state.formStatus = 'valid';
                _this.formErrorText && _this.formErrorText.classList.add('hide');
                // this.botErrorText && this.botErrorText.classList.add('hide')
            }
        };
        this.checkForm = function () {
            var inputs = _this.form.querySelectorAll('.required');
            _this.checkInputs(inputs);
            // antiSpamCheck(this.form);
            _this.updateFormStatus();
        };
        this.submitHandler = function (event) {
            event.preventDefault();
            // const isRobotInput:HTMLInputElement = this.form.querySelector('.isRobotInput');
            // isRobotInput.value = 'false';
            _this.checkForm();
            if (_this.state.formStatus === 'valid') {
                submiter_ts_1.default(_this.form);
                // this.form.submit();
            }
        };
        this.init = function () {
            _this.form.addEventListener('change', _this.checkInput, false);
            _this.form.addEventListener('submit', _this.submitHandler, false);
            // antiSpamCounter(this.form);
            // addIsRobotCheckInput(this.form);
        };
        this.form = props.form;
        this.inputErrorTextSelector = props.inputErrorTextSelector;
        this.formErrorText = this.form.querySelector(props.formErrorTextSelector);
        // this.botErrorText = this.form.querySelector(props.botErrorTextSelector);
        this.submitBtn = this.form.querySelector('.submit');
        this.state = {
            formStatus: ''
        };
        // this.isRobotInput = this.form.querySelector('.isRobotInput');
    }
    return FormValidate;
}());
exports.default = FormValidate;
