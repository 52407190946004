"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function addSourcePageToForm(feildSelector) {
    var fields = document.querySelectorAll(feildSelector + " input");
    if (!fields)
        return false;
    fields.forEach(function (field) {
        field.value = document.title + " (" + document.URL + ")";
    });
}
exports.default = addSourcePageToForm;
;
