"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var saveFormInfo = function () {
    // выбираем на странице все элементы типа textarea и input
    var inputs = document.querySelectorAll('textarea, input');
    inputs.forEach(function (input) {
        // если данные значения уже записаны в sessionStorage, то вставляем их в поля формы
        // путём этого мы как раз берём данные из памяти браузера, если страница была случайно перезагружена
        if (input.value === '')
            input.value = window.sessionStorage.getItem(input.name);
        // на событие ввода данных (включая вставку с помощью мыши) вешаем обработчик
        input.addEventListener('input', function () {
            // и записываем в sessionStorage данные, в качестве имени используя атрибут name поля элемента ввода
            window.sessionStorage.setItem(input.name, input.value);
        });
    });
};
exports.default = saveFormInfo;
